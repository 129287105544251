import { useApi, useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { useEffect, useMemo, useState } from 'react';
import { UserConfig } from '../types';

export const useSdkAdapterConfig = (): UserConfig['adapterConfig'] => {
  const { manifestSupport } = useVimUserMetadata();
  const { resourceUpdater } = useApi();

  const [referralAuthCode, setReferralAuthCode] = useState<boolean>(
    resourceUpdater.canUpdateReferral({
      basicInformation: { authCode: true },
    }).canUpdate,
  );

  useEffect(() => {
    const cb = () => {
      setReferralAuthCode(
        resourceUpdater.canUpdateReferral({
          basicInformation: { authCode: true },
        }).canUpdate,
      );
    };
    resourceUpdater.subscribe('referral', cb);
    return () => {
      resourceUpdater.unsubscribe('referral', cb);
    };
  }, [resourceUpdater]);

  return useMemo(() => {
    return {
      vimConnectCapabilities: {
        supportsUpdateReferral: !!manifestSupport?.EHR.resourceUpdates?.referral,
      },
      unsupportedWritebackFields: {
        updateReferral: referralAuthCode ? [] : ['authCode'],
      },
    };
  }, [manifestSupport?.EHR.resourceUpdates?.referral, referralAuthCode]);
};
